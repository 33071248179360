import { useState, useEffect, useMemo } from "react";
import Slider from "react-slick";
import Box from '@mui/material/Box';
import Link from "next/link";
import PrevArrow from "@components/Components/Reusables/Slick/PrevArrow";
import NextArrow from "@components/Components/Reusables/Slick/NextArrow";
import HomeSliderImage from "./HomeSliderImage";

const HomeBanner2 = ({ slides }) => {
  const [fade, setFade] = useState(false);

  useEffect(() => {
    setFade(true);
  }, []);

  const settings = useMemo(() => ({
    dots: false,
    infinite: true,
    fade: fade, 
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: <PrevArrow sx={{ left: '2px' }} />,
    nextArrow: <NextArrow sx={{ right: '2px' }} />,
  }), [fade]); 

  const slideElements = useMemo(() => slides.map((slide, idx) => (
    <Link href={slide.link_1} key={slide.name}>
      <HomeSliderImage slide={slide} idx={idx} />
    </Link>
  )), [slides]);

  return (
    <Box
      sx={{
        '.slick-slider': {
          padding: 0,
          marginBottom: '22px',
        },
      }}
    >
      <Slider {...settings}>
        {slideElements}
      </Slider>
    </Box>
  );
};

export default HomeBanner2;
