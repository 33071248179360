import Next from '@mui/icons-material/ArrowForward';
import IconButton from "@mui/material/IconButton";

const slickPrev = {
    fontSize: 0,
    lineHeight: 0,
    position: 'absolute',
    top: '42%',
    display: 'block',
    width: '35px',
    height: '35px',
    padding: 0,
    cursor: 'pointer',
    color: 'white',
    backgroundColor: 'primary.main',
    color: 'primary.contrastText',
    zIndex: 1,
	transition: 'all 0.2s ease-in-out',
    ':hover': {
		backgroundColor: 'primary.main',
		color: 'primary.contrastText',
    },
	borderRadius: 0
}

const NextArrow = (props) => {
    const { className, style, onClick, sx } = props;
    
    return (
        <IconButton
            className="slick-arrow-next"
            sx={{ 
                ...slickPrev,
                ...sx
            }}
            onClick={onClick}
        >
            <Next />
        </IconButton>
    )
}

export default NextArrow